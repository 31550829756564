import Vue from 'vue'
import Vuex from 'vuex'
import { setStroe, getStore } from "@/util/store.js";
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    // 表单数据
    analysisForm: getStore({
      name: 'AnalysisForm'
    }),
    userInfo: getStore({
      name: 'userInfo'
    }),
    analysisData: getStore({
      name: 'AnalysisData'
    }),
    uploadConfig: getStore({
      name: 'uploadConfig'
    }),
  },
  getters: {
    getAnalysisForm: state => state.analysisForm,
    getUserInfo: state => state.userInfo,
    getAnalysisData: state => state.analysisData,
    getUploadConfig: state => state.uploadConfig
  },
  mutations: {
    // 持久化存储表单
    setAnalysisForm(state, data) {
      state.analysisForm = data
      setStroe({
        name: 'AnalysisForm',
        content: data
      })
    },
    setUserInfo(state, data) {
      state.userInfo = data
      setStroe({
        name: 'userInfo',
        content: data
      })
    },
    setAnalysisData(state, data) {
      state.analysisData = data
      setStroe({
        name: 'AnalysisData',
        content: data
      })
    },
    setUploadConfig: (state, data) => {
      state.uploadConfig = data
      setStroe({
        name: 'uploadConfig'
      })
    }
  },
  actions: {
  },
})
