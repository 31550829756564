import Vue from 'vue';
import VueRouter from 'vue-router';
import HomeView from '@/views/home/Home.vue';
import ShareView from '@/views/share/index.vue';
import NoPage from '@/views/nopage/index.vue';
import pageAgreement from '@/views/agreement/index.vue';
import canvas from '@/views/canvas/index.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      title: '天星健康'
    }
  },
  {
    path: '/share',
    name: 'share',
    component: ShareView,
    meta: {
      title: '检测报告'
    }
  },
  {
    path: '/useragreement.html',
    name: 'Agreement',
    component: pageAgreement,
    meta: {
      title: '健康镜用户协议'
    }
  },
  {
    path: '/canvas',
    name: 'Canvas',
    component: canvas,
    meta: {
      title: '健康镜用户协议'
    }
  },
  {
    path: '/useragreement',
    name: 'Agreement',
    component: pageAgreement,
    meta: {
      title: '健康镜用户协议'
    }
  },
  {
    path: '/analysis/home',
    name: 'analysisHome',
    component: () => import('@/views/analysis/home.vue'),
    meta: {
      title: '体态评估报告'
    }
  },
  {
    path: '/analysis/page',
    name: 'analysisPage',
    component: () => import('@/views/analysis/page.vue'),
    meta: {
      title: '体态评估报告'
    }
  },
  {
    path: '/analysis/reult',
    name: 'analysisResult',
    component: () => import('@/views/analysis/result.vue'),
    meta: {
      title: '体态评估报告'
    }
  },
  {
    path: '/analysis/reulta',
    name: 'analysisResultA',
    component: () => import('@/views/analysis/resultA.vue'),
    meta: {
      title: '体态评估报告'
    }
  },
  {
    path: '/healthclass/article',
    name: 'healthArticle',
    component: () => import('@/views/healthclass/article.vue'),
    meta: {
      title: '天星健康'
    }
  },
  {
    path: '/device/serial',
    name: 'deviceSerial',
    component: () => import('@/views/device/serial.vue'),
    meta: {
      title: '绑定设备'
    }
  },
  {
    path: '/device/number',
    name: 'deviceNumber',
    component: () => import('@/views/device/number.vue'),
    meta: {
      title: '绑定设备'
    }
  },
  {
    path: '*',
    name: 'NoPage',
    component: NoPage,
    meta: {
      title: '页面不存在'
    }
  }
];

const router = new VueRouter({
  routes
});

export default router;
