// import { serialize } from '@/util/store'
import { getStore, serialize } from '@/util/store.js'
import NProgress from 'nprogress' // progress bar
// import { Toast } from 'vant';
import 'nprogress/nprogress.css'
import qs from 'qs'
import axios from 'axios'
const errorCode = {
  '000': '操作太频繁，请勿重复请求',
  '401': '当前操作没有权限',
  '403': '当前操作没有权限',
  '404': '资源不存在',
  '417': '未绑定登录账号，请使用密码登录后绑定',
  '423': '演示环境不能操作，如需了解联系管理员',
  '426': '用户名不存在或密码错误',
  '428': '验证码错误,请重新输入',
  '429': '请求过频繁',
  '479': '演示环境，没有权限操作',
  'default': '系统未知错误,请反馈给管理员'
}
axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL

//  axios.defaults.baseURL = '/api'

// let url = window.location.href
axios.defaults.timeout = 1200000
// 返回其他状态吗
axios.defaults.validateStatus = function (status) {
  return status >= 200 && status <= 500 // 默认的
}
// axios.defaults.withCredentials = true
// HTTPrequest拦截
axios.interceptors.request.use(config => {
  // config.headers['Content-Type'] = "application/json;charset=utf-8"
  let token = getStore({
    name: 'token'
  })
  if (token) {
    config.headers['Authorization'] = token
    console.log(token,'toe');
    
  }
  if (config.url.indexOf('/device/set') !== -1 || config.url.indexOf('device/bindOs') ) {
    let unique = getStore({
      name: 'unique'
    })
    if (unique) {
      config.headers['Unique-Value'] = unique
      console.log(unique, 'unique');
    }
  }

  if (config.url !== '/pms/antifakecode/process') {
    NProgress.start() // start progress bar
  }
  // headers中配置serialize为true开启序列化
  if (config.method === 'post' && config.headers.serialize) {
    config.data = serialize(config.data)
    delete config.data.serialize
  }

  if (config.method === 'get') {
    config.paramsSerializer = function (params) {
      return qs.stringify(params, { arrayFormat: 'repeat' })
    }
  }

  return config
}, error => {
  return Promise.reject(error)
})
// HTTPresponse拦截
axios.interceptors.response.use(res => {
  NProgress.done()
  const status = Number(res.status) || 0
  const code = Number(res.data.code) ||  Number(res.data.StatusCode) || 0
  const message = res.data?.msg || errorCode[code] || errorCode['default']
  let url = res.config.url
  if(!url.includes('oss') && !url.includes('/device/set') && !url.includes('/device/bindOs') && !url.includes('/device/setSs') && !url.includes('/device/bindSs') ) {
    if (status !== 200 || code !== 200) {
      // Toast({
      //   message: message,
      //   type: 'html'
      // })
      return Promise.reject(new Error(message))
    }
  }

  let data = res.data || res
  if(url.includes('/device/set') || url.includes('/device/bindOs') || url.includes('/device/setSs') || url.includes('/device/bindSs') ) {
    data.status =status;
  }
  return data
}, error => {
  NProgress.done()
  return Promise.reject(new Error(error))
})

export default axios
